<template>
  <div class="notifications">
    <div class="container">

      <div v-if="notifications.length">
        <div class="notification" v-for="notification in notifications" :key="notification">
          <p>
            <strong>New Campaign Alert!</strong> <br />
            <span>Hello Influencer, New Fintech Campaign is now active. Apply right away, but hurry!</span>  <br />
            <small>July 23, 2022 at 9:30AM</small>
          </p>
          <van-divider />
        </div>
      </div>

      <van-empty description="No notifications found!" v-if="!loading && !notifications.length" image="https://influencer.soocel.com/img/no-campaign.f391a06e.png">
      </van-empty>

      <div class="text-center" style="margin: 50px 0 0;" v-if="loading">
        <van-loading color="#f54153" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notifications',
  data () {
    return {
      loading: false,
      notifications: []
    }
  }
}
</script>

<style scoped>
  .container {
    min-height: 100vh;
    padding: 12px 20px;
  }
  .van-divider {
    margin: 15px 0;
  }
  p span {
    opacity: 0.7;
  }
  p small {
    opacity: 0.5;
  }
</style>
